<template>
  <tr class="line-item">
    <td class="text-center">{{ item.id }}</td>
    <td class="text-center">{{ item.name }}</td>
    <td class="text-center">{{ item.email }}</td>
    <td class="text-center">
      <v-icon @click="open">mdi-pencil</v-icon>
    </td>
  </tr>
</template>
<script>
  export default {
    name: 'RoleItem',
    props: {
      item: {
        type: Object,
        required: true,
      },
    },
    methods: {
      open () {
        this.$router.push(`/customers/edit/${this.item.id}`)
      },
    },
  }
</script>
<style scoped>
.line-item {
  height: 50px;
}
</style>
