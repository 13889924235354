<template>
  <v-container
    id="customers-view"
    fluid
    tag="section"
  >
    <material-card
      dark
      icon="mdi-clipboard-text"
      icon-small
      title="Users"
      color="accent"
    >
      <v-data-table
        :headers="headers"
        :loading="loading"
        :items="customers"
      >
        <template v-slot:body="{ items }">
          <item
            v-for="item in items"
            :key="item.id"
            :item="item"
          />
        </template>
      </v-data-table>
    </material-card>
  </v-container>
</template>
<script>
  import { mapActions, mapGetters } from 'vuex'
  import Item from '@/components/Customers/Item'

  export default {
    name: 'Customers',
    components: {
      Item,
    },
    data: () => ({
      loading: false,
      headers: [
        { text: 'ID', align: 'center', width: '20%', value: 'id' },
        { text: 'Name', align: 'center', width: '20%', value: 'name' },
        { text: 'Email', align: 'center', width: '20%', value: 'email' },
        { text: 'Action', align: 'center', width: '20%', sortable: false, value: '' },
      ],
    }),
    computed: {
      ...mapGetters({
        customers: 'customer/getCustomers',
      }),
    },
    mounted () {
      this.loading = true
      this.loadUsersList()
        .finally(() => {
          this.loading = false
        })
    },
    methods: {
      ...mapActions({
        loadUsersList: 'customer/loadCustomers',
      }),
    },
  }
</script>
